import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import Modal from 'react-responsive-modal';
import GoogleMapReact from 'google-map-react';
import { toast } from 'react-toastify';

import Breadcrumb from '../common/breadcrumb';
import api from '../../config/axios';
import Loader from '../common/loader';
import { date, time } from '../../utils/date';

export const renderTextPedido = (purchase) => {
    let text = '';
    if (purchase.quantity > 0) {
        text = `${purchase.quantity} recarga${purchase.quantity > 1 ? 's' : ''}`;
        if (
            purchase.quantity_new_bottles +
                purchase.quantity_starterkit +
                purchase.quantity_dispenser >
            0
        ) {
            text = text + ' y otros...';
        }
        return text;
    } else {
        if (purchase.quantity_starterkit > 0) {
            text = `${purchase.quantity_starterkit} kit de inicio`;
            if (purchase.quantity_new_bottles + purchase.quantity_dispenser > 0) {
                text = text + ' y otros...';
            }
            return text;
        }

        if (purchase.quantity_new_bottles > 0) {
            text = `${purchase.quantity_new_bottles} botellon nuevo`;
            if (purchase.quantity_dispenser > 0) {
                text = text + ' y otros...';
            }
            return text;
        }

        if (purchase.quantity_dispenser > 0) {
            text = `${purchase.quantity_dispenser} dispensador${
                purchase.quantity_dispenser > 1 ? 'es' : ''
            }`;
            return text;
        }
        return text;
    }
};

const ClientDetail = withRouter((props) => {
    const { handleSubmit, register, errors, setValue, clearError, getValues, watch } = useForm();
    const {
        handleSubmit: handleSubmitPush,
        register: registerPush,
        errors: errorsPush,
    } = useForm();
    const {
        handleSubmit: handleSubmitClient,
        register: registerClient,
        errors: errorsClient,
    } = useForm();
    const { history } = props;
    const clientId = props.match.params.id;

    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState([]);
    const [settings, setSettings] = useState(null);
    const [modalAddress, setModalAddress] = useState({
        data: null,
        visible: false,
    });
    const [modalDeleteAddress, setModalDeleteAddress] = useState({
        data: null,
        visible: false,
    });
    const [modalCorreo, setModalCorreo] = useState(false);
    const [updateClient, setUpdateClient] = useState(false);

    useEffect(() => {
        register({ name: 'location' }, { required: true });
        Promise.all([fetchSettings(), fetchClientInfo()]).then((values) => {
            setSettings(values[0]);
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (loaded && client.addresses.length > 0) {
            client.addresses.map((address, index) => {
                let map = new window.google.maps.Map(document.getElementById(`map-${index}`), {
                    center: { lat: address.latitude, lng: address.longitude },
                    zoom: 17,
                    mapTypeControl: false,
                    streetViewControl: false,
                });
                new window.google.maps.Marker({
                    position: { lat: address.latitude, lng: address.longitude },
                    map,
                });
            });
        }
    }, [loaded]);

    useEffect(() => {
        if (modalAddress.visible) {
            let coordinates = null;
            if (modalAddress.data) {
                coordinates = {
                    latitude: modalAddress.data.latitude,
                    longitude: modalAddress.data.longitude,
                };
            } else {
                coordinates = {
                    latitude: 7.776,
                    longitude: -72.2127,
                };
            }
            setValue('location', coordinates);
        }
    }, [modalAddress]);

    const fetchSettings = async () => {
        const { data } = await api.get(`/settings`);
        const settings = data.data[0];

        return settings;
    };

    const fetchClientInfo = async () => {
        const clientRequest = api.get(`/client/${clientId}`);
        const addressesRequest = api.get(`/client/${clientId}/address`);
        const purchasesRequest = api.get(`/client/${clientId}/purchase`);
        const clubRequest = api.get(`/client/${clientId}/club`);
        const clienSubscription = api.get(`/client/${clientId}/subscription`);

        const values = await Promise.all([
            clientRequest,
            addressesRequest,
            purchasesRequest,
            clubRequest,
            clienSubscription,
        ]);

        const client = values[0];
        const addresses = values[1];
        const purchases = values[2];
        const club = values[3];
        const subscription = values[4];

        setClient({
            ...client.data.data,
            subscription: subscription && subscription.data ? subscription.data.data : null,
            addresses: addresses.data.data.rows,
            purchases: purchases.data.data.rows,
            club: club.data.data,
        });
        return;
    };

    const handleApiLoaded = (map, maps) => {
        const triangleCoords = settings.boundary.map((item) => ({
            lat: item.latitude,
            lng: item.longitude,
        }));

        var bermudaTriangle = new maps.Polygon({
            paths: triangleCoords,
            strokeColor: '#00a4d3',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00a4d3',
            fillOpacity: 0.15,
        });
        bermudaTriangle.setMap(map);
    };

    const handleClientLocation = (data) => {
        setValue('location', null);
        const coordinates = {
            latitude: data.center.lat(),
            longitude: data.center.lng(),
        };
        setValue('location', coordinates);
    };

    const addClientLocation = (data) => {
        const dataAddress = {
            street1: data.street1,
            street2: data.street2,
            house_number: 'N/A',
            latitude: data.location.latitude,
            longitude: data.location.longitude,
            longway: data.longway,
        };
        api.post(`/client/${clientId}/address`, dataAddress)
            .then(() => {
                toast.info('Dirección agregada con éxito');
                setModalAddress({ visible: false });
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((error) => {
                toast.error('Hubo un error al agregar la dirección. Intente de nuevo');
            });
    };

    const updateClientLocation = (data) => {
        const dataAddress = {
            street1: data.street1,
            street2: data.street2,
            newBottles: data.newBottles,
            observation: data.observation,
            house_number: 'N/A',
            latitude: data.location.latitude,
            longitude: data.location.longitude,
            longway: data.longway,
        };

        api.put(`/client/${clientId}/address/${modalAddress.data.id}`, dataAddress)
            .then(() => {
                toast.info('Dirección modificada con éxito');
                history.push(`/clientes/${clientId}`);
                setModalAddress({ visible: false });
            })
            .catch((error) => {
                toast.error('Hubo un error al modificar la dirección. Intente de nuevo');
            });
    };

    const updateClientInformation = (data) => {
        const dataInformation = {
            name: data.name,
            phone_number: data.phone_number,
            email: data.email,
        };

        api.put(`/client/${clientId}`, dataInformation)
            .then(() => {
                toast.info('Información del cliente modificada con éxito');
                setUpdateClient(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((error) => {
                if (error.response.data) {
                    toast.error(error.response.data.errors[0].msg);
                } else {
                    console.log(error);
                    toast.error('Hubo un error. Intenta de nuevo');
                }
            });
    };

    const deleteAddress = (id) => {
        api.delete(`/client/${clientId}/address/${id}`)
            .then(() => {
                toast.info('Dirección eliminada con éxito');
                setModalDeleteAddress({ visible: false });
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((error) => {
                if (error.response.data) {
                    toast.error(error.response.data.errors[0].msg);
                } else {
                    console.log(error);
                    toast.error('Hubo un error. Intenta de nuevo');
                }
            });
    };

    const sendPushNotification = (data) => {
        const notificationPush = {
            ...data,
            data: {
                view: data.view || null,
                id: data.viewId || null,
                link: data.link || null,
            },
        };
        api.post(`/client/${clientId}/notification`, notificationPush)
            .then(() => {
                toast.success('Notificatión enviada con éxito');
            })
            .catch((error) => {
                toast.error('Hubo un error al enviar la notificación. Intente de nuevo');
            });
    };

    const sendEmailClientOffline = (email) => {
        api.get(`/client/offline?email=${email}`)
            .then(() => {
                toast.success('Correo enviado con éxito');
                setModalCorreo(false);
            })
            .catch((error) => {
                toast.error('Hubo un error al enviar el correo. Intente de nuevo');
            });
    };

    const renderClientPurchases = () => {
        const multipleAddresses = client.addresses.length > 1;
        return client.purchases.map((item, index) => {
            const fechaPedidoCreado = item.transactions.find(
                (item) => item.name === 'Pedido Creado',
            );
            return (
                <div key={`purchase-client-${index}`}>
                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <p>
                                <a href={`${process.env.PUBLIC_URL}/recargas/detalle/${item.id}`}>
                                    {item.id}
                                </a>
                            </p>
                            {multipleAddresses && (
                                <p style={{ margin: 0, padding: 0 }}>{item.address.street1}</p>
                            )}
                            <p style={{ margin: 0, color: 'green' }}>{item.couponId && 'Cupón'}</p>
                            <p style={{ margin: 0 }}>{item.paymentMethod.name}</p>
                            <p>
                                <strong>{renderTextPedido(item)}</strong>
                            </p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <p className="mt-0 mb-0">{date(fechaPedidoCreado.createdAt)}</p>
                            <p className="mt-0 mb-0">
                                <strong>{time(fechaPedidoCreado.createdAt)}</strong>
                            </p>
                        </div>
                    </div>
                    <hr />
                </div>
            );
        });
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const locationClient = getValues('location');
    const greatPlaceStyle = {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    };

    const phoneParsed = client.phone_number;
    const whatsappUrl = `https://wa.me/${phoneParsed}`;

    return (
        <Fragment>
            <Breadcrumb title="Detalle de cliente" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-sm-12 d-flex justify-content-end mt-1">
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={() => setModalCorreo(true)}
                                    >
                                        <i className="fa fa-envelope"></i>
                                    </button>
                                    &nbsp;&nbsp;
                                    <a
                                        type="button"
                                        className="btn btn-success"
                                        href={whatsappUrl}
                                        target="_blank"
                                    >
                                        <i className="fa fa-whatsapp"></i>
                                    </a>
                                </div>
                                <form className="needs-validation user-add" noValidate="">
                                    <h4>Información personal</h4>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span></span> ID Cliente:
                                        </label>
                                        <strong className=" col-xl-8 col-md-7">
                                            {client.id} - {client.tokenInvitation}
                                        </strong>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span></span> Puntos:
                                        </label>
                                        <strong className=" col-xl-8 col-md-7">
                                            {client.club.points} (
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/clientes/${client.id}/cupones`}
                                            >
                                                Cupones
                                            </Link>
                                            )
                                        </strong>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span></span> Recargas a favor:
                                        </label>
                                        <strong className=" col-xl-8 col-md-7">
                                            {client.subscription.availability}
                                        </strong>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Nombre
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={client.name}
                                            type="text"
                                            disabled={!updateClient}
                                            name="name"
                                            ref={registerClient({ required: true })}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Email
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={client.email}
                                            type="email"
                                            disabled={!updateClient}
                                            name="email"
                                            ref={registerClient({
                                                required: true,
                                                pattern:
                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                                            })}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Teléfono
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={client.phone_number}
                                            type="text"
                                            disabled={!updateClient}
                                            name="phone_number"
                                            ref={registerClient({ required: true })}
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-sm-12 text-center mt-1">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => setUpdateClient(!updateClient)}
                                            >
                                                {updateClient
                                                    ? 'Cancelar'
                                                    : 'Modificar info cliente'}
                                            </button>
                                            &nbsp;&nbsp;
                                            {updateClient && (
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={handleSubmitClient(
                                                        updateClientInformation,
                                                    )}
                                                >
                                                    Guardar
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                                <div className="needs-validation user-add mt-5">
                                    <h4>Direcciones de entrega</h4>

                                    {client.addresses.map((address, index) => (
                                        <div key={`address-${index}`} className="form-group row">
                                            <p className="col-xl-12" style={{ margin: 0 }}>
                                                Dirección:{' '}
                                                <strong>
                                                    {address.street1}, {address.street2}
                                                </strong>
                                            </p>
                                            <p className="col-xl-12" style={{ margin: 0 }}>
                                                Botellones nuevos:{' '}
                                                <strong>{address.newBottles}</strong>
                                            </p>
                                            <p className="col-xl-12" style={{ margin: 0 }}>
                                                Observación:{' '}
                                                <strong>{address.observation || 'N/A'}</strong>
                                            </p>
                                            <p className="col-xl-12" style={{ margin: 0 }}>
                                                Larga:{' '}
                                                <strong>{address.longway ? 'Sí' : 'No'}</strong>
                                            </p>
                                            <br />
                                            <div
                                                className="col-xl-12"
                                                style={{
                                                    width: '100%',
                                                    height: 400,
                                                }}
                                            >
                                                <div
                                                    id={`map-${index}`}
                                                    className="col-xl-12"
                                                    style={{ width: '100%', height: 400 }}
                                                ></div>
                                            </div>
                                            <div className="col-sm-12 text-right mt-1">
                                                {!address.default && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                            setModalDeleteAddress({
                                                                visible: true,
                                                                data: address.id,
                                                            })
                                                        }
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                )}
                                                &nbsp;&nbsp;
                                                <button
                                                    type="submit"
                                                    className="btn btn-info"
                                                    onClick={() =>
                                                        setModalAddress({
                                                            visible: true,
                                                            data: address,
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-sm-12 text-center mt-5 mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={() => setModalAddress({ visible: true })}
                                        >
                                            Agregar Nueva dirección
                                        </button>
                                    </div>
                                    <Modal
                                        open={modalAddress.visible}
                                        center
                                        classNames={{ modal: 'modal-update-address' }}
                                        onClose={() => setModalAddress({ visible: false })}
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title f-w-600">
                                                Agregar dirección
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row product-adding">
                                                <div className="col-xl-10 offset-xl-1">
                                                    <div className="needs-validation add-product-form">
                                                        <div className="form form-label-center">
                                                            <div className="form-group mb-4 row">
                                                                <div className="col-12">
                                                                    <input
                                                                        type="street1"
                                                                        className="form-control"
                                                                        placeholder="Ingresa avenida, calle, carrera, vereda"
                                                                        name="street1"
                                                                        defaultValue={
                                                                            modalAddress.data &&
                                                                            modalAddress.data
                                                                                .street1
                                                                        }
                                                                        ref={register({
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    {errors.street1 &&
                                                                        errors.street1.type ===
                                                                            'required' && (
                                                                            <p className="message-error-input">
                                                                                Dirección
                                                                                obligatoria
                                                                            </p>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-4 row">
                                                                <div className="col-xl-12">
                                                                    <input
                                                                        type="street2"
                                                                        className="form-control"
                                                                        placeholder="Ingrese número de casa, piso y/o apto"
                                                                        name="street2"
                                                                        defaultValue={
                                                                            modalAddress.data &&
                                                                            modalAddress.data
                                                                                .street2
                                                                        }
                                                                        ref={register({
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    {errors.street2 &&
                                                                        errors.street2.type ===
                                                                            'required' && (
                                                                            <p className="message-error-input">
                                                                                Este campo es
                                                                                obligatorio
                                                                            </p>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-4 row">
                                                                <div className="col-xl-12">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Cantidad botellones nuevos"
                                                                        name="newBottles"
                                                                        defaultValue={
                                                                            modalAddress.data &&
                                                                            modalAddress.data
                                                                                .newBottles
                                                                        }
                                                                        ref={register({
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    {errors.newBottles &&
                                                                        errors.newBottles.type ===
                                                                            'required' && (
                                                                            <p className="message-error-input">
                                                                                Este campo es
                                                                                obligatorio
                                                                            </p>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-4 row">
                                                                <div className="col-xl-12">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Tipo de envase. Información importante de la dirección"
                                                                        name="observation"
                                                                        defaultValue={
                                                                            modalAddress.data &&
                                                                            modalAddress.data
                                                                                .observation
                                                                        }
                                                                        ref={register({
                                                                            required: false,
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-4 row">
                                                                <div className="col-xl-12">
                                                                    <label>
                                                                        <input
                                                                            style={{
                                                                                width: 30,
                                                                                display: 'inline',
                                                                            }}
                                                                            type="checkbox"
                                                                            className="form-control"
                                                                            id="longway"
                                                                            name="longway"
                                                                            value={true}
                                                                            defaultChecked={
                                                                                modalAddress.data &&
                                                                                modalAddress.data
                                                                                    .longway
                                                                            }
                                                                            ref={register({
                                                                                required: false,
                                                                            })}
                                                                        />
                                                                        Dirección larga
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-4 row">
                                                                <div className="col-12">
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            height: 300,
                                                                        }}
                                                                    >
                                                                        <GoogleMapReact
                                                                            bootstrapURLKeys={{
                                                                                key: 'AIzaSyDECMBhHDb83sHE52nNUxHlpDu721Nm6j4',
                                                                            }}
                                                                            defaultCenter={
                                                                                modalAddress.data
                                                                                    ? {
                                                                                          lat: modalAddress
                                                                                              .data
                                                                                              .latitude,
                                                                                          lng: modalAddress
                                                                                              .data
                                                                                              .longitude,
                                                                                      }
                                                                                    : {
                                                                                          lat: 7.776,
                                                                                          lng: -72.2127,
                                                                                      }
                                                                            }
                                                                            onDragEnd={
                                                                                handleClientLocation
                                                                            }
                                                                            onGoogleApiLoaded={({
                                                                                map,
                                                                                maps,
                                                                            }) =>
                                                                                handleApiLoaded(
                                                                                    map,
                                                                                    maps,
                                                                                )
                                                                            }
                                                                            defaultZoom={17}
                                                                            yesIWantToUseGoogleMapApiInternals={
                                                                                true
                                                                            }
                                                                        >
                                                                            {locationClient && (
                                                                                <div
                                                                                    lat={
                                                                                        locationClient.latitude
                                                                                    }
                                                                                    lng={
                                                                                        locationClient.longitude
                                                                                    }
                                                                                    style={
                                                                                        greatPlaceStyle
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src="https://icon-library.com/images/google-maps-circle-icon/google-maps-circle-icon-2.jpg"
                                                                                        alt="marker-map"
                                                                                        style={{
                                                                                            width: 40,
                                                                                            height: 40,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </GoogleMapReact>
                                                                    </div>
                                                                    {errors.location &&
                                                                        errors.location.type ===
                                                                            'required' && (
                                                                            <p className="message-error-input">
                                                                                Ubicación en el mapa
                                                                                es obligatoria
                                                                            </p>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleSubmit(
                                                    modalAddress.data
                                                        ? updateClientLocation
                                                        : addClientLocation,
                                                )}
                                            >
                                                {modalAddress.data ? 'Modificar' : 'Guardar'}{' '}
                                                dirección
                                            </button>
                                        </div>
                                    </Modal>
                                    <Modal
                                        open={modalCorreo}
                                        center
                                        style={{ width: 700 }}
                                        onClose={() => setModalCorreo(false)}
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title f-w-600">
                                                Enviar correo de recuperar contraseña
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            <p>
                                                ¿Está seguro de enviar un correo electrónico al
                                                cliente para recuperar su contraseña?{' '}
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => sendEmailClientOffline(client.email)}
                                            >
                                                Enviar correo
                                            </button>
                                        </div>
                                    </Modal>
                                    <Modal
                                        open={modalDeleteAddress.visible}
                                        center
                                        style={{ width: 700 }}
                                        onClose={() => setModalDeleteAddress({ visible: false })}
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title f-w-600">
                                                Dirección por defecto
                                            </h5>
                                        </div>
                                        <div className="modal-body">
                                            <p>¿Está seguro de eliminar esta dirección? </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    deleteAddress(modalDeleteAddress.data)
                                                }
                                            >
                                                Sí
                                            </button>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        {client.expoToken && (
                            <div className="card">
                                <div className="card-body">
                                    <form
                                        onSubmit={handleSubmitPush(sendPushNotification)}
                                        className="needs-validation user-add"
                                        noValidate=""
                                    >
                                        <h4>Enviar notificación push</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">
                                                <span>*</span> Título
                                            </label>
                                            <input
                                                className="form-control col-xl-3 col-md-7"
                                                type="text"
                                                name="title"
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errorsPush.title &&
                                                    errorsPush.title.type === 'required' &&
                                                    'Titulo obligatorio'}
                                            </p>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">
                                                <span>*</span> Mensaje
                                            </label>
                                            <input
                                                className="form-control col-xl-5 col-md-7"
                                                type="text"
                                                name="body"
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            <p className="message-error-input">
                                                {errorsPush.body &&
                                                    errorsPush.body.type === 'required' &&
                                                    'Mensaje obligatorio'}
                                            </p>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">
                                                <span></span> Vista
                                            </label>
                                            <input
                                                className="form-control col-xl-3 col-md-7"
                                                placeholder="RecargarBotellon o DetailOrder"
                                                type="text"
                                                name="view"
                                                ref={registerPush({
                                                    required: false,
                                                })}
                                            />
                                            <label className="col-xl-1 col-md-4">
                                                <span></span> ID Vista
                                            </label>
                                            <input
                                                className="form-control col-xl-2 col-md-7"
                                                placeholder="Necesario para un detalle de orden"
                                                type="text"
                                                name="viewId"
                                                ref={registerPush({
                                                    required: false,
                                                })}
                                            />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">
                                                <span></span> Link
                                            </label>
                                            <input
                                                className="form-control col-xl-5 col-md-7"
                                                placeholder="https://www.instagram.com/p/CNqYSqwhSMs/"
                                                type="text"
                                                name="link"
                                                ref={registerPush({
                                                    required: false,
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-12 text-center mt-5 mb-3">
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                                onClick={handleSubmitPush(sendPushNotification)}
                                            >
                                                Enviar notificación
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                        <div className="card">
                            <div className="card-body">
                                <div className="needs-validation user-add">
                                    <h4>Historial de Pedidos</h4>
                                    <div>
                                        {client.purchases.length > 0
                                            ? renderClientPurchases()
                                            : 'Este cliente no ha adquirido ningún producto/servicio'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default ClientDetail;
